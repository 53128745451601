<template>
    <div class="all_main_wrap">
        <HeaderBox>
            <template #title> 云豆明细 </template>
        </HeaderBox>
        <!-- 表格列表 -->
        <div class="table-box">
            <a-table class="table-template" :rowKey="(item) => item.id" :loading="detailloading"
                :columns="detailcolumns" :data-source="detailTableData" :pagination="{
                    total: ctotal,
                    current: cpageNumber,
                    pageSize: cpageSize,
                    showTotal: (res) => `共${ctotal}条`,
                }" bordered @change="oncPage">
                <template slot="index" slot-scope="item, row, index">
                    {{ (cpageNumber - 1) * cpageSize + index + 1 }}
                </template>

                <!-- <template slot="operation" slot-scope="item">
                    <div class="blueText">
                        <span @click="deleteAction(item.id)">删除</span>
                    </div>
                </template> -->

            </a-table>
        </div>
    </div>
</template>

<script>

const detailcolumns = [
    {
        title: "序号",
        width: "80px",
        align: "center",
        dataIndex: "index",
        scopedSlots: { customRender: "index" },
    },
    {
        title: "云豆标题",
        align: "center",
        dataIndex: "title",
    },
    {
        title: "云豆明细",
        align: "center",
        dataIndex: "detail",
    },
    {
        title: "获得时间",
        align: "center",
        dataIndex: "changeTime",
        scopedSlots: { customRender: "changeTime" },
    },
    {
        title: "获得方式",
        align: "center",
        dataIndex: "typeName",
    },
    {
        title: "获得云豆",
        align: "center",
        dataIndex: "pointsChange",
    },
    {
        title: "剩余云豆",
        align: "center",
        dataIndex: "remainingPoints",
        scopedSlots: { customRender: "remainingPoints" },
    }, {
        title: "过期时间",
        align: "center",
        dataIndex: "expireDate",
    }, {
        title: "备注信息",
        align: "center",
        dataIndex: "remark",
    },
    //{
    //    title: "操作",
    //    align: "center",
    //   width: "220px",
    //    scopedSlots: { customRender: "operation" },
    //},
];
import HeaderBox from "@/components/HeaderBox.vue";
import Derive from "@/components/derive.vue"; // 导出组件

export default {
    // 可用组件的哈希表
    components: { HeaderBox, Derive },
    // 接收传值
    props: {},
    // 数据对象
    data() {
        return {

            // 云豆详情变量
            detailcolumns, // 表头
            detailTableData: [], // 列表数据
            ctotal: 0,
            cpageNumber: 1,
            cpageSize: 10,
            category: false,
            currUserId: undefined, // 当前详情的userid
            detailloading: false, // 列表加载
        };
    },
    // 事件处理器
    methods: {
        fetchDetail() {
            this.detailloading = true
            this.$ajax({
                url: "/hxclass-management/user/points/selUserPointsDetailList",
                params: {
                    userId: this.currUserId,
                    pageNum: this.cpageNumber,
                    pageSize: this.cpageSize,
                },
            }).then((res) => {
                this.detailloading = false
                if (res.code == 200 && res.success) {
                    this.ctotal = res.data.total;
                    this.detailTableData = res.data.records;
                    this.category = true;
                }
            });
        },
        categoryCancel() {
            this.category = false;
            this.currUserId = undefined;
            this.detailTableData = [];
        },
        oncPage(e) {
            this.cpageNumber = e.current;
            this.cpageSize = e.pageSize;
            this.fetchDetail();
        },
        deleteAction(id) {
            let _that = this
            this.$confirm({
                title: '确定删除此条云豆数据吗?',
                okText: '确认',
                onOk() {
                    _that.ondeleteClick(id)
                },
                onCancel() { },
            });
        },
        ondeleteClick(id) {
            this.detailloading = true
            this.$ajax({
                url: "/hxclass-management/user/points/deleted",
                method: "PUT",
                params: {
                    id: id
                }
            }).then((res) => {
                this.detailloading = false
                if (res.code == 200 && res.success) {
                    this.fetchDetail();
                }
            });
        }
    },
    // 生命周期-实例创建完成后调用
    created() {
        this.currUserId = this.$route.query.userId;
        this.fetchDetail();
    },
    // 生命周期-实例挂载后调用
    mounted() { },
    // 生命周期-实例销毁离开后调用
    destroyed() { },
    // 计算属性监听
    computed: {
        rowSelection() {
            return {
                type: "radio",
                onChange: this.select,
            };
        },
    },
    // 自定义的侦听器
    watch: {},
};
</script>

<style lang="less" scoped>
.time {
    display: inline-block;

    /deep/ .ant-select {
        margin: 0 0px 14px 0 !important;
    }

    /deep/.ant-select-selection--single {
        height: 32px !important;
    }

    /deep/.ant-calendar-picker {
        width: 380px !important;
    }
}

.btn {
    margin-right: 24px;
}

.table-box {
    /deep/ .ant-tabs-bar {
        border-bottom: none;
    }
}

/deep/.right-box {
    padding-bottom: 5px !important;
}

.modalDiv {
    >p {
        font-weight: 500;
        color: #333333;
    }

    .code-item {
        >span {
            display: inline-block;
            min-width: 100px;
            margin: 5px 0;
            margin-right: 15px;
        }
    }
}

/deep/.ant-modal-body {
    overflow: hidden;
}

/deep/.ant-modal-title {
    text-align: center;
    color: #000000;
}

/deep/.ant-modal-header {
    border-bottom: none;
}

/deep/.ant-modal-footer {
    text-align: center;
    border-top: none;
}

/deep/ .ant-modal-body {
    padding: 14px 36px 0px;
}

.table-template {
    /deep/.ant-table {
        border: none;
    }
}

.search-box {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    .search-input {
        width: 36%;
    }
}
</style>